<template>
    <v-dialog
      v-model="visible"
      persistent
      width="500">

      <v-card>
        <v-card-title class="text-h5 primary">
          <p style="color: #ffffff">Coming Soon!</p>
        </v-card-title>

        <v-card-text>
          Hello there! Unfortunately you are still a little early! We know you're eager to use PropCheck but we haven't launched the platform just yet. 
          Please keep an eye on <a href="https://propcheck.com.au/coming-soon/?avia_forced_reroute=1">this page</a> and check back soon.        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeBeforeLaunchDialog"
          >
          OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
const EVENT_NAME = "close-before-launch-dialog";
export default {
  props: {
    value: Boolean,
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(EVENT_NAME);
      },
    },
  },
  methods: {
    closeBeforeLaunchDialog: function () {
      this.$emit(EVENT_NAME);
    },
  },
};
</script>
